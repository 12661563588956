import request from '@/utils/request'
// 获取主办审核列表
export function GethostList(data) {
  return request({
    url: '/user/admin/meeting/host/list',
    method: 'POST',
    data,
  })
}

// 主办审核
export function editHostStatus(data) {
  return request({
    url: '/user/admin/meeting/host/audit',
    method: 'POST',
    data,
  })
}

// 展览审核列表

export function GetexhibitionList(data) {
  return request({
    url: '/user/admin/hold/exhibition/list',
    method: 'POST',
    data,
  })
}

// 主办审核
export function editExhibitionStatus(data) {
  return request({
    url: '/user/admin/hold/exhibition/audit',
    method: 'POST',
    data,
  })
}

// 添加注册码
export function addRegisterCode(params) {
  return request({
    url: '/user/admin/invitation/createInvitationCode',
    method: 'get',
    params,
  })
}

// 查询注册码
export function getRegisterCodeList(params) {
  return request({
    url: '/user/admin/invitation/getList',
    method: 'get',
    params,
  })
}

// 注册码报表导出

export function exportRegisterCodeList(params) {
  return request({
    url: '/user/admin/invitation/getReport',
    method: 'get',
    params,
  })
}

// 作废注册码
export function zuofeiRegisterCode(data) {
  return request({
    url: `/user/admin/invitation/updateStatus?invitationCode=${data.invitationCode}`,
    method: 'post',
  })
}

// 短信邀请

export function smsInviteUser(params) {
  return request({
    url: '/user/admin/invitation/sms/invitation',
    method: 'get',
    params,
  })
}

// 短信邀请批量

export function smsInviteUserByBatch(data) {
  return request({
    url: '/user/admin/invitation/sms/invitation/list',
    method: 'POST',
    data,
  })
}

// 短信邀请记录

export function smsInviteUserList(params) {
  return request({
    url: '/user/admin/invitation/sms/invitation/history',
    method: 'get',
    params,
  })
}
